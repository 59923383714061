import { wait } from "./request";

import GLS_COVER_001 from "@/img/001/gls.png";
import GLS_MODEL_001 from "@/img/001/gls.png";
import GLS_LEFT_MODEL_001 from "@/img/001/gls_leg_l.png";
import GLS_RIGHT_MODEL_001 from "@/img/001/gls_leg_r.png";
import GLS_COVER_002 from "@/img/002/gls.png";
import GLS_MODEL_002 from "@/img/002/gls.png";
import GLS_LEFT_MODEL_002 from "@/img/002/gls_leg_l.png";
import GLS_RIGHT_MODEL_002 from "@/img/002/gls_leg_r.png";
import GLS_COVER_003 from "@/img/003/gls.png";
import GLS_MODEL_003 from "@/img/003/gls.png";
import GLS_LEFT_MODEL_003 from "@/img/003/gls_leg_l.png";
import GLS_RIGHT_MODEL_003 from "@/img/003/gls_leg_r.png";

const GLASSES_BASIC_1 = {
  id: "0x001",
  name: "波米希亚风",
  cover: GLS_COVER_001,
  shopLink: 'https://baidu.com',
  model: {
    texture: {
      frame: GLS_MODEL_001,
      legL: GLS_LEFT_MODEL_001,
      legR: GLS_RIGHT_MODEL_001,
    },
    spec: {
      frameWidth: 140, //mm 镜框总宽度
      frameHeight: 42, // 镜框总高度
      glassWidth: 52, // 镜片宽度
      glassHeight: 34, // 镜片高度
      bridgeWidth: 18, // 鼻梁宽度
      templeLength: 136, // 镜腿长度
    },
  },
};
const GLASSES_BASIC_2 = {
  id: "0x002",
  name: "简约",
  cover: GLS_COVER_002,
  shopLink: '/photo',
  model: {
    texture: {
      frame: GLS_MODEL_002,
      legL: GLS_LEFT_MODEL_002,
      legR: GLS_RIGHT_MODEL_002,
    },
    spec: {
      frameWidth: 140, //mm 镜框总宽度
      frameHeight: 42, // 镜框总高度
      glassWidth: 52, // 镜片宽度
      glassHeight: 34, // 镜片高度
      bridgeWidth: 18, // 鼻梁宽度
      templeLength: 136, // 镜腿长度
    },
  },
};
const GLASSES_BASIC_3 = {
  id: "0x003",
  name: "上流",
  cover: GLS_COVER_003,
  model: {
    texture: {
      frame: GLS_MODEL_003,
      legL: GLS_LEFT_MODEL_003,
      legR: GLS_RIGHT_MODEL_003,
    },
    spec: {
      frameWidth: 140, //mm 镜框总宽度
      frameHeight: 42, // 镜框总高度
      glassWidth: 52, // 镜片宽度
      glassHeight: 34, // 镜片高度
      bridgeWidth: 18, // 鼻梁宽度
      templeLength: 136, // 镜腿长度
    },
  },
};

export const getGlassesList = async () => {
  await wait(2);
  return [
    { ...GLASSES_BASIC_1, id: "0x001" },
    { ...GLASSES_BASIC_2, id: "0x002" },
    { ...GLASSES_BASIC_3, id: "0x003" },
    { ...GLASSES_BASIC_1, id: "0x004" },
    { ...GLASSES_BASIC_2, id: "0x005" },
    { ...GLASSES_BASIC_3, id: "0x006" },
    { ...GLASSES_BASIC_1, id: "0x007" },
    { ...GLASSES_BASIC_2, id: "0x008" },
    { ...GLASSES_BASIC_3, id: "0x009" },
  ];
};
