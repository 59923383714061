import "webrtc-adapter";
import fx from "glfx";

/**
 * 打开摄像头
 * @param {MediaConstraints} constraints - 媒体控制参数
 */
export const openCamera = (constraints) => {
  return navigator.mediaDevices.getUserMedia({
    audio: false,
    video: true,
    ...constraints,
  });
};
/**
 * 在canvas上绘制视频,循环绘制，最大60帧。
 * ！！记得使用完毕后调用cancel方法清理定时器，避免内存泄漏
 * @param {HtmlVideoElement}} videoEl 视频节点
 * @param {HtmlCanvasElement} canvas 展示视频的canvas节点
 * @param {uint} frameRate 帧率，默认不节流，60帧展示
 * @return number 返回函数句柄，执行.cancel()取消该方法循环执行
 */
export const paintingVideoOnCanvas = (videoEl, canvas, frameRate) => {
  const { videoWidth, videoHeight } = videoEl;
  canvas.width = videoWidth;
  canvas.height = videoHeight;
  const ctx = canvas.getContext("2d");

  const beautifyCanvas = fx.canvas(); // 美颜画布
  const exponent = Math.min(videoWidth, videoHeight) / 3; // ≈9/2

  const interval = frameRate ? Math.round(1000 / frameRate) : 60;
  const handler = setInterval(() => {
    // 磨皮+提亮
    const textureVideo = beautifyCanvas.texture(videoEl);
    beautifyCanvas
      .draw(textureVideo)
      .brightnessContrast(0.05, 0) // 提亮
      .denoise(exponent) // 双边滤波降噪
      .vibrance(0.56) // 饱和度
      .update();

    // 处理摄像头左右镜象反转
    ctx.save();
    ctx.transform(-1, 0, 0, 1, 0, 0);
    ctx.drawImage(beautifyCanvas, -videoWidth, 0, videoWidth, videoHeight);
    ctx.restore();
  }, interval);

  return {
    cancel: () => {
      clearInterval(handler);
    },
  };
};
/**
 * 设置video元素多媒体源，处理src和srcObject兼容性问题
 * Reference: MDN-getUserMedia
 * @param {HtmlVideoElement}} videoEl 视频节点
 * @param {MediaStream} mediaStream 媒体流：视频/相机
 */
export const setVideoSrc = (videoEl, mediaStream) => {
  if ("srcObject" in videoEl) {
    try {
      videoEl.srcObject = mediaStream;
    } catch (err) {
      if (err.name !== "TypeError") {
        throw err;
      }
      // Even if they do, they may only support MediaStream
      videoEl.src = URL.createObjectURL(mediaStream);
    }
  } else {
    videoEl.src = URL.createObjectURL(mediaStream);
  }
};
