import React, { Component } from "react";
import "webrtc-adapter";
import { Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";

import Home from "./views/Home";
import ImageInput from "./views/ImageInput";
import VideoInput from "./views/VideoInput";
import Video3DInput from "./views/Video3DInput";
import Glasses from "./views/Glasses";
import LiveTry from "./live-try/VanityTable";

class App extends Component {
  render() {
    return (
      <Router history={createBrowserHistory()}>
        <div className="route">
          <Route exact path="/" component={Home} />
          <Route exact path="/photo" component={ImageInput} />
          <Route exact path="/camera" component={VideoInput} />
          <Route exact path="/camera-3d" component={Video3DInput} />
          <Route exact path="/glasses" component={Glasses} />
          <Route exact path="/live-try" component={LiveTry} />
        </div>
      </Router>
    );
  }
}

export default App;
