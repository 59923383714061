import React, { Component } from "react";
import GlassesList from "./components/GlassesList/GlassesList";
import stl from "./ArtifactDraw.module.scss";

export default class ArtifactDraw extends Component {
  state = {};
  handleGlassesSelect = (glasses) => {
    const { onGlassesSelect } = this.props;
    onGlassesSelect && onGlassesSelect(glasses);
    const { shopLink } = glasses || {};
    this.setState({ shopLink });
  };

  render() {
    const { shopLink } = this.state;
    return (
      <div className={stl.draw}>
        <div className={stl.glasses}>
          <GlassesList onChange={this.handleGlassesSelect} />
        </div>
        {shopLink && <a href={shopLink} className={stl["go-detail"]}>查看详情</a>}
      </div>
    );
  }
}
