import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as faceapi from "face-api.js";
import { loadModels, getFullFaceDescription } from "../api/face";
import BackButton from './BackButton'

// Import image to test API
const testImg = require("../img/test.jpg");

// Initial State
const INIT_STATE = {
  imageURL: testImg,
  fullDesc: null
};

class ImageInput extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INIT_STATE };
    this.refCanvas = React.createRef();
    this.refImg = React.createRef();
  }

  componentDidMount = async () => {
    await loadModels();
    await this.handleImage(this.state.imageURL);
  };

  handleImage = async (image = this.state.imageURL) => {
    // faceapi.draw.drawFaceLandmarks(canvas, resizedResult);
    // 图片越小需要的inputSize越大越精准
    await getFullFaceDescription(image, 448).then(fullDesc => {
      console.log(fullDesc, this.refCanvas, this.refImg);
      if (!!fullDesc) {
        this.refCanvas.current.width = this.refImg.current.naturalWidth;
        this.refCanvas.current.height = this.refImg.current.naturalHeight;
        faceapi.draw.drawFaceLandmarks(
          this.refCanvas.current,
          fullDesc.landmarks
        );

        const ctx = this.refCanvas.current.getContext('2d');
        fullDesc.landmarks.positions.forEach((point, index) => {
          ctx.font = "11px san-serif"
          ctx.fillStyle="black"
          ctx.fillText(index, point.x + 1, point.y + 1)
        });

        this.setState({ fullDesc });
      }
    });
  };

  render() {
    const { imageURL, fullDesc } = this.state;

    return (
      <div className="cn-wrap">
        <img
          ref={this.refImg}
          className="cn-media"
          src={imageURL}
          alt="imageURL"
        />
        <canvas className="cn-cover" ref={this.refCanvas} />
        {!fullDesc && <div>detecting</div>}
        <BackButton />
      </div>
    );
  }
}

export default withRouter(ImageInput);
