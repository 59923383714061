import React, { Component } from "react";
import Stats from "stats-js";
import raf from "raf";

export default class StatsPanel extends Component {
  refPanel = React.createRef();
  componentDidMount() {
    this.stats = new Stats();
    this.refPanel.current.appendChild(this.stats.dom);
    raf(this.animate);
  }
  animate = () => {
    if (!this.stats) return
    this.stats.begin();
    this.stats.end();
    raf(this.animate);
  };
  componentWillUnmount() {
    this.stats = null;
  }

  render() {
    return <div ref={this.refPanel} />;
  }
}
