import React, { Component } from "react";
import raf from "raf";
import * as THREE from "three";
import { loadModels, getVideoFaceDescription } from "../api/face";
import GLS_MODEL from "../img/000_gls.png";
import GLS_LEFT_MODEL from "../img/000_gls_leg_l.png";
import GLS_RIGHT_MODEL from "../img/000_gls_leg_r.png";
import BackButton from "./BackButton";
import Stats from "./Stats";

export default class Video3DInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.canvas = React.createRef();
    this.canvas3D = React.createRef();
    this.forwardTimes = [];
    this.state = {};
    this.ZHeight = 100;
  }
  componentDidMount() {
    loadModels().then(() => {
      this.openCamera();
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  animate = () => {
    if (!this.renderer) return;
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.webcamCam);
    // this.renderer.autoClear = false;
    this.renderer.render(this.scene, this.camera);
  };
  initRenderWithCam = () => {
    console.log(this.webcam);
    if (!this.webcam || !this.webcam.current) return;
    const video = this.webcam.current;
    const { videoWidth, videoHeight } = video;

    this.scene = new THREE.Scene();
    this.webcamCam = new THREE.OrthographicCamera(-1024, 1024, 1024, -1024);
    const texture = new THREE.VideoTexture(video);
    const meshWebcam = new THREE.Mesh(new THREE.PlaneBufferGeometry(videoWidth, videoHeight), new THREE.MeshBasicMaterial({ map: texture }));
    this.webcamCam.position.set(0, 0, 0); //设置相机坐标,放置z=0，对眼镜进行截面处理
    // this.webcamCam.lookAt(this.webcamScene.position); //让相机指向场景中心

    this.scene.add(meshWebcam);

    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas3D.current, antialias: true, preserveDrawingBuffer: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(videoWidth, videoHeight, false);
    // document.body.appendChild(this.renderer.domElement);
  };
  init = () => {
    if (!this.webcam || !this.webcam.current) return;
    const video = document.getElementById("video");
    const { videoWidth, videoHeight } = video;

    this.camera = new THREE.PerspectiveCamera(90, videoWidth / videoHeight, 1, 1000);
    this.ZHeight = Math.round(Math.sqrt(Math.pow(videoWidth / 2, 2) + Math.pow(videoHeight / 2, 2)) / 2);
    this.camera.position.z = this.ZHeight + 1;
    console.log(this.ZHeight)
    // const texture = new THREE.VideoTexture(video);

    // 摄像头
    // const material = new THREE.SpriteMaterial({ map: texture });
    // const width = videoWidth;
    // const height = videoHeight;
    // const spriteC = new THREE.Sprite(material);
    // spriteC.center.set(0.5, 0.5);
    // spriteC.scale.set(width, height, 1);
    // this.scene.add(spriteC);

    const glassRotation = [(Math.PI / 180) * 5, (-Math.PI / 180) * 20, (Math.PI / 180) * 10];
    // 眼镜
    // 脸宽120
    const FaceWidth = 120;
    // 镜面
    new THREE.TextureLoader().load(GLS_MODEL, texture => {
      const { width, height } = texture.image;
      // image_size = 600x246
      const scale = width / FaceWidth;
      const glassWidth = width / scale;
      const glassHeight = height / scale;
      const glassDeep = width / scale;
      const glassMeshGroup = new THREE.Group(); // 镜架组

      const mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(glassWidth, glassHeight),
        new THREE.MeshBasicMaterial({ map: texture, transparent: true })
      );
      mesh.position.set(0, 0, glassDeep);
      glassMeshGroup.add(mesh);

      // 左镜腿
      new THREE.TextureLoader().load(GLS_LEFT_MODEL, texture => {
        texture.minFilter = THREE.NearestFilter;
        const mesh = new THREE.Mesh(
          new THREE.PlaneBufferGeometry(glassDeep, glassHeight),
          new THREE.MeshBasicMaterial({ map: texture, transparent: true, side: THREE.DoubleSide }) // FrontSide || DoubleSide
        );
        mesh.rotateY((-Math.PI / 180) * 90); // 顺时针转动90度
        mesh.position.set(glassWidth / -2, 0, glassDeep / 2);
        glassMeshGroup.add(mesh);

        // 右镜腿
        new THREE.TextureLoader().load(GLS_RIGHT_MODEL, texture => {
          texture.minFilter = THREE.NearestFilter;
          const mesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(glassDeep, glassHeight),
            new THREE.MeshBasicMaterial({ map: texture, transparent: true, side: THREE.DoubleSide }) // DoubleSide
          );

          mesh.rotateY((Math.PI / 180) * 90); // 逆时针转动90度
          mesh.position.set(glassWidth / 2, 0, glassDeep / 2);
          glassMeshGroup.add(mesh);

          // 转动镜架
          // glassMeshGroup.position.setZ();
          glassMeshGroup.rotation.set(...glassRotation);
          this.scene.add(glassMeshGroup);
        });
      });
    });
  };
  openCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          width: { ideal: 720 },
          height: { ideal: 480 }
        }
      })
      .then(stream => {
        this.webcam.current.srcObject = stream;
      })
      .catch(err => console.log(err.name + ": " + err.message));
  };
  onVideoCanPlay = () => {
    // const video = this.webcam.current;
    // const canvas = this.canvas3D.current;
    // this.renderer = this.initialVideoRender(video, canvas);
    // this.renderVideo(video);
    // this.renderGlass();
    this.init();
    this.initRenderWithCam();
    this.animate();
  };

  render() {
    return (
      <div className="video-page">
        {/* {!time && <div>please waiting···</div>} */}
        <div onClick={this.openCamera}>打开摄像头</div>
        <div className="camera-wrap" style={{ position: "relative" }}>
          <video
            videoconstraints={{
              facingMode: "user",
              width: { ideal: 720 },
              height: { ideal: 960 }
            }}
            ref={this.webcam}
            id="video"
            autoPlay
            style={{ display: "none" }}
            playsInline
            onCanPlay={this.onVideoCanPlay}
          ></video>
          <canvas className="video-cover" ref={this.canvas3D}></canvas>
          <canvas className="video-cover" ref={this.canvas}></canvas>
        </div>
        <BackButton />
        <Stats />
      </div>
    );
  }
}
