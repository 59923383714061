import React, { Component } from "react";
import { getGlassesList } from "@/api/mock";
import Spin from "@/components/Spin";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css"; // 自定义样式没有用到
import GlassesCard from "./GlassesCard";
import stl from "./GlassesList.module.scss";

export default class GlassesList extends Component {
  state = { list: undefined };
  componentDidMount() {
    getGlassesList()
      .then((list) => {
        this.setState({ list }, () => {
          if (Array.isArray(list) && list.length) {
            // 列表初始化结束后加载第一个眼镜模型(也可缓存标记位置)
            this.handleSlideChange(0, 0);
          }
        });
      })
      .catch((err) => {
        this.setState({ list: null });
      });
  }
  componentWillUnmount() {
    clearTimeout(this.tempSlideTimer)
  }
  /**
   * 传递当前用户选中的眼镜
   */
  dispatchGlass = (glasses) => {
    console.log("dispatch glasses", glasses.id, "at", new Date().toISOString());
    const { onChange } = this.props;
    typeof onChange === "function" && onChange(glasses);
  };

  // handleClick = (glasses, event) => {
  //   console.log("click", glasses);
  //   this.dispatchGlass(glasses);
  // };
  handleSlideChange = (prevIdx, nextIdx) => {
    // console.log("slide from ", prevIdx, " to ", nextIdx);
    // NOTE: afterChange is broken. See:
    // https://github.com/akiran/react-slick/issues/1262. Therefoe this hacky solution
    clearTimeout(this.tempSlideTimer)
    this.tempSlideTimer = setTimeout(() => {
      const glasses = this.state.list[nextIdx];
      this.dispatchGlass(glasses);
    }, 256);
  };

  render() {
    const { list } = this.state;
    if (!Array.isArray(list)) {
      return <Spin />;
    }
    if (!list.length) {
      return <div className={stl.empty}>暂无眼镜上架哦</div>;
    }

    // 眼镜卡片封面距两侧间距
    const space = Math.round(Math.min(window.innerWidth, 768) * 0.312);
    const setting = {
      className: "center",
      infinite: false,
      arrows: false,
      autoPlay: false,
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 256,
      beforeChange: this.handleSlideChange,
      centerMode: true,
      centerPadding: `${space}px`,
      swipeToSlide: true,
      focusOnSelect: true,
    };

    return (
      <div className={stl.scroll}>
        <Slider {...setting}>
          {list.map((item) => (
            <GlassesCard key={item.id} data={item} />
          ))}
        </Slider>
      </div>
    );
  }
}
