import React, { Component } from "react";
import raf from "raf";
import * as THREE from "three";
import { loadModels, getVideoFaceDescription } from "../api/face";
import GLS_MODEL from "../img/gls.png";
import BackButton from "./BackButton";
import StatsPanel from "./Stats";

export default class Video3DInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.canvas = React.createRef();
    this.canvas3D = React.createRef();
    this.forwardTimes = [];
    this.state = {};
    this.ZHeight = 100;
  }
  componentDidMount() {
    loadModels().then(() => {
      this.openCamera();
    });
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  animate = () => {
    if (!this.renderer) return;
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  };
  init = () => {
    if (!this.webcam || !this.webcam.current) return;
    const video = document.getElementById("video");
    const { videoWidth, videoHeight } = video;

    this.camera = new THREE.PerspectiveCamera(90, videoWidth / videoHeight, 1, 1000);
    this.ZHeight = Math.round(Math.sqrt(Math.pow(videoWidth / 2, 2) + Math.pow(videoHeight / 2, 2)) / 2);
    this.camera.position.z = this.ZHeight;

    this.scene = new THREE.Scene();
    var texture = new THREE.VideoTexture(video);

    // 摄像头
    var material = new THREE.SpriteMaterial({ map: texture });
    var width = videoWidth;
    var height = videoHeight;
    const spriteC = new THREE.Sprite(material);
    // spriteC.center.set(0.5, 0.5);
    spriteC.scale.set(width, height, 1);
    this.scene.add(spriteC);

    this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas3D.current });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(videoWidth, videoHeight, false);
    // document.body.appendChild(this.renderer.domElement);
  };
  openCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
          width: { ideal: 720 },
          height: { ideal: 480 },
          frameRate: 15
        }
      })
      .then(stream => {
        this.webcam.current.srcObject = stream;
      })
      .catch(err => console.log(err.name + ": " + err.message));
  };
  detect = async () => {
    const video = this.webcam.current;

    await getVideoFaceDescription(video).then(desc => {
      if (!desc) return;
      const { positions } = desc.landmarks;
      // 侧脸过渡不再计算
      if (positions[17].x < positions[0].x || positions[26].x > positions[16].x) return;

      // 暂时不会向量运算，先用投影计算
      // 依次利用关键点，计算绕Y轴，Z周旋转，即转头和外头动作；低头动作暂不考虑
      const A = positions[27].x - positions[17].x;
      const B = positions[26].x - positions[27].x;
      const tanAngleY = (this.ZHeight * (B - A)) / (A * B + A * A);
      // const angleY = Math.atan(tanAngleY) / (Math.PI / 180);
      // const glsHalfLen = A / Math.cos(angleY * (Math.PI / 180))
      const angleY = Number(Math.atan(tanAngleY).toFixed(6)); // 统一不处理成角度值 // (Math.PI / 180);
      const glsHalfLen = A / Math.cos(angleY);

      const leftEyeCenter = [
        (positions[37].x + positions[38].x + positions[40].x + positions[41].x) / 4,
        (positions[37].y + positions[38].y + positions[40].y + positions[41].y) / 4
      ];
      const rightEyeCenter = [
        (positions[43].x + positions[44].x + positions[47].x + positions[46].x) / 4,
        (positions[43].y + positions[44].y + positions[47].y + positions[46].y) / 4
      ];
      const tangleZ = (rightEyeCenter[1] - leftEyeCenter[1]) / (rightEyeCenter[0] - leftEyeCenter[0]);
      const angleZ = Number(Math.atan(tangleZ).toFixed(6)) || 0;

      // 眼镜
      const glsWidth = glsHalfLen * 2;
      if (!this.spriteGlass) {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(GLS_MODEL, texture => {
          this.glassTexture = texture;
          const material = new THREE.SpriteMaterial({ map: texture });
          const width = material.map.image.width;
          const height = material.map.image.height;
          const glsHeight = (glsHalfLen * height) / width;

          this.spriteGlass = new THREE.Sprite(material);
          this.spriteGlass.center.set(0.5, 0.5);
          this.spriteGlass.scale.set(Math.round(glsWidth), Math.round(glsHeight), 1);
          // this.spriteGlass.scale.set(width, height, 1);

          // 旋转
          this.spriteGlass.rotateY(angleY);
          // this.spriteGlass.rotateZ(angleZ);

          console.log(angleY, angleZ, glsWidth, glsHeight, leftEyeCenter, rightEyeCenter);
          this.scene.add(this.spriteGlass);

          var cubeGeo = new THREE.BoxGeometry(glsWidth, glsHeight, glsWidth); //创建立方体
          var cubeMat = new THREE.MeshLambertMaterial({
            //创建材料
            color: 0x666666,
            wireframe: true
          });
          var cubeMesh = new THREE.Mesh(cubeGeo, cubeMat); //创建立方体网格模型
          console.log(positions[17])
          console.log('----------------')
          cubeMesh.position.set(0,0, 1); //设置立方体的坐标
          this.scene.add(cubeMesh); //将立方体添加到场景中
        });
      } else {
        const width = this.glassTexture.image.width;
        const height = this.glassTexture.image.height;
        const glsHeight = (glsHalfLen * height) / width;

        this.spriteGlass.scale.set(Math.round(glsWidth), Math.round(glsHeight), 1);
        // 旋转
        this.spriteGlass.setRotationFromEuler(new THREE.Euler(0, angleY, angleZ));
        // this.spriteGlass.rotateZ(angleZ);

        console.log(angleY, angleZ, glsWidth, glsHeight, leftEyeCenter, rightEyeCenter);
        // this.scene.add(this.spriteGlass);
      }
    });

    this.timer = raf(this.detect);
  };
  onVideoCanPlay = () => {
    // const video = this.webcam.current;
    // const canvas = this.canvas3D.current;
    // this.renderer = this.initialVideoRender(video, canvas);
    // this.renderVideo(video);
    // this.renderGlass();
    this.init();
    this.animate();
    this.detect();
  };

  render() {
    const { meter } = this.state;
    const { fps, time } = meter || {};
    return (
      <div className="video-page">
        {/* {!time && <div>please waiting···</div>} */}
        <div onClick={this.openCamera}>打开摄像头</div>
        <div className="camera-wrap" style={{ position: "relative" }}>
          <video ref={this.webcam} id="video" autoPlay style={{ display: "none" }} playsInline onCanPlay={this.onVideoCanPlay}></video>
          <canvas className="video-cover" ref={this.canvas3D}></canvas>
          <canvas className="video-cover" ref={this.canvas}></canvas>
        </div>
        <div className="gls_area">{}</div>
        <div className="fps_meter">
          <label htmlFor="time">Time:</label>
          <input disabled value={time || "-"} id="time" type="text" />
          <label htmlFor="fps">Estimated Fps:</label>
          <input disabled value={fps || ""} id="fps" type="text" />
          <br />
          <label>
            Input Size: <b>160 x 160</b>
          </label>
          &nbsp;&nbsp;
          <label>
            Score Threshold: <b>0.6</b>
          </label>
        </div>
        <BackButton />
        <StatsPanel />
      </div>
    );
  }
}
