import * as THREE from "three";
import ResourceTracker from "./ResourceTracker";

export default class Glasses {
  // 资源管理器
  #resManager;
  /**
   * 镜架对象,右mesh组成的Group
   * @type THREE.Group
   */
  mesh = null;
  width = 0;
  height = 0;
  deep = 0;

  /**
   * 眼镜架
   * @param {Texture} textureFrame 镜架主框贴图
   * @param {Texture} textureTempleL 左镜腿贴图
   * @param {Texture} textureTempleR 右镜腿贴图
   */
  constructor(textureFrame, textureTempleL, textureTempleR) {
    // 准备资源追踪回收
    this.#resManager = new ResourceTracker();
    const track = this.#resManager.track;

    // 镜架结构组
    this.mesh = track(new THREE.Group());

    // 镜架尺寸 - 以下基于假设：左右眼镜腿尺寸是一样的
    const { width: glassWidth, height: glassHeight } = textureFrame.image;
    const { width: glassDeep } = textureTempleL.image;
    this.width = glassWidth;
    this.height = glassHeight;
    this.deep = glassDeep;

    // 左右镜腿插值计算
    textureTempleL.minFilter = THREE.NearestFilter;
    textureTempleL.magFilter = THREE.NearestFilter;
    textureTempleR.minFilter = THREE.NearestFilter;
    textureTempleR.magFilter = THREE.NearestFilter;

    // - 前镜框
    const meshFrame = track(
      new THREE.Mesh(
        track(new THREE.PlaneGeometry(glassWidth, glassHeight)),
        track(new THREE.MeshBasicMaterial({ map: textureFrame, transparent: true }))
      )
    );
    meshFrame.position.set(0, 0, glassDeep);

    // - 左镜腿/视觉右侧   :-镜腿贴图左右视觉一样DoubleSide
    const meshTempleL = track(
      new THREE.Mesh(
        track(new THREE.PlaneGeometry(glassDeep, glassHeight)),
        track(new THREE.MeshBasicMaterial({ map: textureTempleL, transparent: true, side: THREE.DoubleSide }))
      )
    );
    meshTempleL.rotateY(THREE.MathUtils.degToRad(90)); // 逆时针转动90度
    meshTempleL.position.set(glassWidth / 2, 0, glassDeep / 2); // 贴图position是以中心点定位的

    // 右镜腿/视觉左侧
    const meshTempleR = track(
      new THREE.Mesh(
        track(new THREE.PlaneGeometry(glassDeep, glassHeight)),
        track(new THREE.MeshBasicMaterial({ map: textureTempleR, transparent: true, side: THREE.DoubleSide }))
      )
    );
    meshTempleR.rotateY(THREE.MathUtils.degToRad(-90)); // 顺时针转动90度
    meshTempleR.position.set(-glassWidth / 2, 0, glassDeep / 2);

    this.mesh.add(meshFrame);
    this.mesh.add(meshTempleL);
    this.mesh.add(meshTempleR);
  }

  dispose = () => {
    this.#resManager.dispose();
    this.#resManager = null;
  };
}
