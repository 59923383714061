import React from "react";
import "./GlassesCard.scss";

const GlassesCard = (props) => {
  const { onClick, data } = props;
  const { name, cover, id } = data;
  const handleClick = (e) => {
    onClick && onClick(data, e);
  };

  return (
    <div className="glasses" onClick={handleClick}>
      <img className="glasses-cover" src={cover} alt="" />
      <div className="glasses-name">
        {name}-{id}
      </div>
    </div>
  );
};

export default GlassesCard;
