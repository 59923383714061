import React, { Component } from "react";
import Webcam from "react-webcam";
import raf from "raf";
import * as faceapi from "face-api.js";
import { loadModels } from "../api/face";
import GLS_MODEL from "../img/gls.png";
import BackButton from "./BackButton";
import StatsPanel from "./Stats";

export default class VideoInput extends Component {
  constructor(props) {
    super(props);
    this.webcam = React.createRef();
    this.canvas = React.createRef();
    this.forwardTimes = [];
    this.state = {
      fullDesc: null,
      detections: null,
      loaded: false
    };
  }
  componentDidMount = async () => {
    const glsImg = new Image();
    glsImg.onload = () => {
      this.gls = glsImg;
    };
    glsImg.src = GLS_MODEL;

    await loadModels();
    this.setState({ loaded: true });
    this.options = new faceapi.TinyFaceDetectorOptions({
      inputSize: 224,
      scoreThreshold: 0.6
    });
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onPlay = async () => {
    if (!this.webcam || !this.webcam.current || !this.state.loaded) return;
    const { video } = this.webcam.current;
    const { current: canvas } = this.canvas;

    // const video = this.webcam;
    if (video.paused || video.ended) {
      this.timer = setTimeout(this.onPlay);
      return;
    }

    const ts = Date.now();

    const result = await faceapi
      .detectSingleFace(video, this.options)
      .withFaceLandmarks(true); // tiny

    if (result) {
      const dims = faceapi.matchDimensions(
        canvas,
        { width: video.videoWidth, height: video.videoHeight },
        true
      );
      const resizedResult = faceapi.resizeResults(result, dims);

      this.drawResult(canvas, resizedResult);

      this.updateTimeStats(Date.now() - ts);
    } else {
      this.clearCanvas(canvas)
    }
    this.timer = raf(this.onPlay);
  };
  drawResult = (canvas, resizedResult) => {
    //   result: { detection: FaceDetection
    //   landmarks: FaceLandmarks68
    //   unshiftedLandmarks: FaceLandmarks68
    //   alignedRect: FaceDetection}
    // faceapi.draw.drawFaceLandmarks(canvas, resizedResult);

    this.drawGls(canvas, resizedResult);
  };
  clearCanvas = canvas => {
    var ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  drawGls = (canvas, resizedResult) => {
    if (!this.gls) return;

    const ctx = canvas.getContext("2d");
    const { positions } = resizedResult.landmarks;

    const faceHalfWidth = Math.max(
      positions[27].x - positions[17].x,
      positions[26].x - positions[27].x
    );
    const glassWidth = Math.round(faceHalfWidth * 2);
    const glassHeight =
      Math.round(glassWidth * this.gls.height) / this.gls.width;

    const rectStartX = Math.round(positions[27].x - glassWidth / 2);
    const rectStartY = Math.round(positions[27].y - glassHeight / 2);

    ctx.drawImage(this.gls, rectStartX, rectStartY, glassWidth, glassHeight);
  };
  drawMockGls = (canvas, resizedResult) => {
    const ctx = canvas.getContext("2d");
    // const { positions } = resizedResult.landmarks
    const leftEye = resizedResult.landmarks.getLeftEye();
    const rightEye = resizedResult.landmarks.getRightEye();
    ctx.fillStyle = "rgb(200,0,0,0.5)";
    ctx.strokeStyle = "rgb(200,0,0,0.5)";
    ctx.lineWidth = 2;

    ctx.moveTo(leftEye[5].x, leftEye[5].y);
    for (let index = 0; index < leftEye.length; index++) {
      ctx.lineTo(leftEye[index].x, leftEye[index].y);
    }
    ctx.stroke();

    ctx.moveTo(rightEye[5].x, rightEye[5].y);
    for (let index = 0; index < rightEye.length; index++) {
      ctx.lineTo(rightEye[index].x, rightEye[index].y);
    }
    ctx.stroke();

    ctx.moveTo(leftEye[2].x, leftEye[2].y);
    ctx.lineTo(rightEye[1].x, rightEye[1].y);
    ctx.stroke();

    ctx.moveTo(leftEye[4].x, leftEye[4].y);
    ctx.lineTo(rightEye[5].x, rightEye[5].y);
    ctx.stroke();
  };

  updateTimeStats(timeInMs) {
    this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30);
    const avgTimeInMs =
      this.forwardTimes.reduce((total, t) => total + t) /
      this.forwardTimes.length;
    this.setState({
      meter: {
        time: `${Math.round(avgTimeInMs)} ms`,
        fps: `${faceapi.utils.round(1000 / avgTimeInMs)}`
      }
    });
  }

  render() {
    const { meter } = this.state;
    const { fps, time } = meter || {};
    return (
      <div className="video-page">
        {!time && <div>please waiting···</div>}
        <div className="camera-wrap" style={{ position: "relative" }}>
          <Webcam
            audio={false}
            ref={this.webcam}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: "user",
              width: { ideal: 720 },
              height: { ideal: 480 }
            }}
            onLoadedMetadata={this.onPlay}
            autoPlay
            playsInline
          />
          <canvas className="video-cover" ref={this.canvas}></canvas>
        </div>
        <div className="fps_meter">
          <label htmlFor="time">Time:</label>
          <input disabled value={time || "-"} id="time" type="text" />
          <label htmlFor="fps">Estimated Fps:</label>
          <input disabled value={fps || ""} id="fps" type="text" />
          <br />
          <label>
            Input Size: <b>160 x 160</b>
          </label>
          &nbsp;&nbsp;
          <label>
            Score Threshold: <b>0.6</b>
          </label>
        </div>
        <BackButton />
        <StatsPanel />
      </div>
    );
  }
}
