import React from "react";
import cx from "classnames";
import ICON from "@/icons/bar.svg";
import "./index.scss";

export default function Spin(props) {
  const { style, height, ...rest } = props;
  return (
    <div className="spin" style={{ ...style, height }} {...rest}>
      <img src={ICON} className={cx("spin-icon")} alt="loading" title="loading" />
    </div>
  );
}
