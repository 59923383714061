import * as faceapi from "face-api.js";

// tiny_face_detector options
let INPUT_SIZE = 160;
let SCORE_THRESHOLD = 0.6;

function getCurrentFaceDetectionNet() {
  return faceapi.nets.tinyFaceDetector;
}

function isFaceDetectionModelLoaded() {
  return !!getCurrentFaceDetectionNet().params;
}

// Load models and weights
export async function loadModels() {
  if (isFaceDetectionModelLoaded()) {
    return;
  }
  const MODEL_URL = process.env.PUBLIC_URL + "/models";
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
  //   await faceapi.loadFaceRecognitionModel(MODEL_URL);
}

export async function getVideoFaceDescription(
  video,
  inputSize = INPUT_SIZE,
  scoreThreshold = SCORE_THRESHOLD
) {
  // tiny_face_detector options
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  });
  const useTinyModel = true;

  // detect all faces and generate full description from image
  // including landmark and descriptor of each face
  let fullDesc = await faceapi
    .detectSingleFace(video, OPTION)
    .withFaceLandmarks(useTinyModel);
  // .withFaceDescriptors();
  return fullDesc;
}
export async function getFullFaceDescription(
  blob,
  inputSize = INPUT_SIZE,
  scoreThreshold = SCORE_THRESHOLD
) {
  // tiny_face_detector options
  const OPTION = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold
  });
  const useTinyModel = true;

  // fetch image to api
  let img = await faceapi.fetchImage(blob);

  // detect all faces and generate full description from image
  // including landmark and descriptor of each face
  let fullDesc = await faceapi
    .detectSingleFace(img, OPTION)
    .withFaceLandmarks(useTinyModel);
  // .withFaceDescriptors();
  return fullDesc;
}
