import "whatwg-fetch";

export const API_ROOT = `${process.env.API_ROOT}`.toLocaleLowerCase();

/**
 * 等待指定时间
 * @param {number} second 秒数
 * @param {any} isFail 失败标志，如果设置将抛出该信息
 */
export const wait = (second, isFail) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      isFail ? reject(new Error(isFail)) : resolve();
    }, second * 1000);
  });

const defaultOptions = {
  method: "GET",
  mode: "cors",
  credentials: "include",
};

function netError(err) {
  if (
    err.message === "Failed to fetch" ||
    err.message === "NetworkError when attempting to fetch resource." ||
    err.message === "Network request failed"
  ) {
    /* eslint 'no-param-reassign': ['off'] */
    err.code = "NETWORK_ERROR";
    err.message = "网络数据异常";
  }
  throw err;
}

function checkStatus(response) {
  const { status } = response;
  if ((status >= 200 && status < 300) || status === 400) {
    return response;
  }
  const error = new Error();
  error.code = status;
  switch (status) {
    case 401:
      error.message = "用户会话过期，请重新登录！";
      break;
    case 403:
      error.message = "拒绝访问!";
      break;
    case 404:
    case 408:
      error.message = "请求资源不存在!";
      break;
    case 500:
    case 502:
    case 503:
    case 504:
    default:
      error.message = "服务器繁忙,请稍后重试！";
      break;
  }
  throw error;
}

function parseCode(res) {
  const { code, message, data } = res || {};
  if (code) {
    const error = new Error();
    error.code = code;
    error.message = message;
    throw error;
  } else {
    return data;
  }
}

export default function request(url, option) {
  const options = {
    ...defaultOptions,
    ...option,
  };
  if (options.method === "POST" || options.method === "PUT" || options.method === "DELETE") {
    if (!(options.body instanceof FormData)) {
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        ...options.headers,
      };
      options.body = JSON.stringify(options.body);
    } else {
      // options.body is FormData
      options.headers = {
        Accept: "application/json",
        ...options.headers,
      };
    }
  }
  if (!/http(s)?:\/\/.+/.test(url)) {
    // 请求地址不包括http、https时走
    url = `${API_ROOT}/${url}`;
  }

  const fetchPromise = fetch(url, options);
  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      const error = new Error();
      error.code = "CLIENT_TIMEOUT";
      error.message = "请求超时，请稍后重试!";
      reject(error);
    }, 20000);
  });

  return Promise.race([fetchPromise, timeoutPromise])
    .catch(netError)
    .then(checkStatus)
    .then((response) => {
      // 204 do not return data by default
      // using .json will report an error.
      if (response.status === 204) {
        return response.text();
      }
      return response.json();
    })
    .then(parseCode);
}
