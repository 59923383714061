import React, { Component } from "react";
import ArtifactDraw from "./ArtifactDraw";
import Mirror from "./components/Mirror/Mirror";
import stl from "./VanityTable.module.scss";
import StatsPanel from "../views/Stats";
import { loadModels } from "@/api/face";
import Spin from "@/components/Spin";

export default class VanityTable extends Component {
  state = {};
  componentDidMount() {
    loadModels().then(() => {
      this.setState({ ready: true });
    });
  }

  handleGlassesChange = (glasses) => {
    this.setState({ glasses });
  };

  render() {
    const { glasses, ready } = this.state;
    if (!ready) return <Spin />;
    return (
      <div className={stl.wrap}>
        <div className={stl.container}>
          <div className={stl.mirror}>
            <Mirror glasses={glasses} />
          </div>
          <div className={stl.draws}>
            <ArtifactDraw onGlassesSelect={this.handleGlassesChange} />
          </div>
        </div>
        <StatsPanel />
      </div>
    );
  }
}
