import * as THREE from "three";
import raf from "raf";
import ResourceTracker from "./ResourceTracker";
import { detectFaceDesc } from "./faceUtil";
import GlassesManager from "./GlassesManager";

const EYE_GLS_ASPECT = 1.1;

export default class Studio {
  /**
   * 场景渲染器
   * @type THREE.WebGLRenderer
   */
  #renderer = null;
  /**
   * 主透视相机
   * @type THREE.PerspectiveCamera
   */
  #cameraMain = null;
  /**
   * 辅助正交相机，用来处理当前场景的动态背景
   * @type THREE.OrthographicCamera
   */
  #cameraOrth = null;
  /**
   * 主场景
   * @type THREE.Scene
   */
  #scene = null;
  /**
   * 动态素材
   * @type THREE.Texture
   */
  #dynamicTexture = null;
  /**
   * 眼镜管理器
   * @type GlassesManager
   */
  #glassesManager = null;
  /**
   * 当前佩戴的眼镜
   * @type Glasses
   */
  #currentGlasses = null;

  /**
   * 资源追踪管理器，用来会搜THREE创建的资源
   * @type ResourceTracker
   */
  #resManager = null;
  // 标记资源可回收方法
  #track = void 0;

  /**
   * 创建摄影棚
   * @param {HtmlCanvasElement} canvas canvas元素
   * @param {[Object]} [options] WebGLRenderer选项
   */
  constructor(canvas, options) {
    this.#resManager = new ResourceTracker();
    this.#track = this.#resManager.track;
    this.#glassesManager = this.#track(new GlassesManager());

    this.#renderer = new THREE.WebGLRenderer({ canvas, antialias: true, preserveDrawingBuffer: true, alpha: true, ...options });
    //! open retina
    // this.#renderer.setPixelRatio(window.devicePixelRatio);

    this.#scene = new THREE.Scene();

    this.#cameraOrth = new THREE.OrthographicCamera(-1024, 1024, 1024, -1024);
    this.#cameraOrth.position.set(0, 0, 0); //设置相机坐标,放置z=0，对眼镜进行截面处理

    this.#cameraMain = new THREE.PerspectiveCamera(90, 0.75);

    this.#track(this.#renderer);
    this.#track(this.#scene);
    this.#track(this.#cameraOrth);
    this.#track(this.#cameraMain);
  }
  /**
   * 销毁资源
   */
  dispose = () => {
    // clear timer
    clearTimeout(this.detectTimer);
    // clear three-s
    this.#resManager.dispose();
    this.#resManager = null;
    this.#renderer = null;
    // other actions
  };
  /**
   * 在控制台输出当前renderer状态信息
   */
  logCurrentRenderInfo = () => {
    console.log("this.renderer.info", this.#renderer.info);
  };

  /**
   * 初始化摄影棚动态背景
   */
  initDynamicBackground = (element) => {
    const { width, height } = element;

    // 添加动态素材到主场景
    this.#dynamicTexture = this.#track(new THREE.Texture(element));
    this.#dynamicTexture.minFilter = THREE.NearestFilter; // 线性插值
    this.#dynamicTexture.magFilter = THREE.NearestFilter; // 线性插值
    const mesh = this.#track(new THREE.Mesh(new THREE.PlaneGeometry(width, height), new THREE.MeshBasicMaterial({ map: this.#dynamicTexture })));
    this.#scene.add(mesh);

    // 设置渲染器尺寸
    this.#renderer.setSize(width, height, false);

    // 设置正交相机规格和机位
    this.#cameraMain = this.#track(new THREE.PerspectiveCamera(90, width / height, 1, 1000));
    this.ZHeight = Math.min(width, height) / 2; // foc=45刚好1:1
    this.#cameraMain.position.z = this.ZHeight;
  };

  changeGlasses = (glasses) => {
    // console.log("ware an new glasses", glasses.id);
    // 缓存并使用镜架
    this.#glassesManager.add(glasses).then((gls) => {
      console.log(this.#currentGlasses);
      this.dynamicDetectFace(gls);
    });
  };
  /**
   * 动态检测人脸
   */
  dynamicDetectFace = async (freshGlass) => {
    console.log("detect");
    const gls = freshGlass || this.#currentGlasses;

    // 如果!(配置了镜架且检测到人脸)
    const beginTime = new Date();

    // 1.检测人脸位置
    const desc = await detectFaceDesc(this.#dynamicTexture.image);
    if (desc) {
      const { positions } = desc.landmarks;
      const leftW = positions[27].x - Math.max(positions[17].x, positions[0].x); // 处理脸转动
      const rightW = Math.min(positions[26].x, positions[16].x) - positions[27].x;

      // ≈ 脸宽
      const faceWReal = Math.round(Math.max(leftW, rightW) * 2 * EYE_GLS_ASPECT);
      const faceW = Math.round(Math.min(leftW, rightW) * 2 * EYE_GLS_ASPECT);

      // ≈ 偏航角 单位：弧度
      const anglY = Math.asinh(1 - faceW / faceWReal) * (leftW > rightW ? 1 : -1); // rad * 180 / Math.PI // 角度

      // 逆时针y负值
      const disEyeX = (positions[43].x + positions[44].x - positions[37].x - positions[38].x) / 2;
      const disEyeY = (positions[37].y + positions[38].y - positions[43].y - positions[44].y) / 2;
      const anglZ = Math.atanh(disEyeY / disEyeX);

      // 2.眼镜架进行变形处理
      // 3.对镜架进行定位
      const { width } = gls;
      const aspect = faceWReal / width;
      // const glassDisplayWidth = ((this.ZHeight - 10) / this.ZHeight) * faceW;
      // const aspect = glassDisplayWidth / width;

      const size = this.#renderer.getSize();
      gls.mesh.position.set(positions[27].x - size.x / 2, size.y / 2 - positions[27].y, faceWReal - this.ZHeight); // 假设镜片离脸10个距离
      gls.mesh.scale.set(aspect, aspect, aspect);
      gls.mesh.rotation.set(0, anglY, anglZ);
      // console.log(aspect, faceWReal);

      // 4.添加镜架到渲染场景
      if (freshGlass) {
        this.#currentGlasses && this.#scene.remove(this.#currentGlasses.mesh)
        this.#currentGlasses = gls;
        this.#scene.add(gls.mesh);
      }
    }

    // 5.开启下一个计算循环
    const remain = 1000 / 20 - (new Date() - beginTime); // 帧率
    this.detectTimer = setTimeout(() => {
      this.dynamicDetectFace();
    }, remain);
  };

  render = () => {
    if (!this.#renderer) return;
    // 刷新动态素材
    this.#dynamicTexture.needsUpdate = true;

    this.#renderer.render(this.#scene, this.#cameraOrth);
    this.#renderer.render(this.#scene, this.#cameraMain);

    raf(this.render); // raf递归刷新
  };
}
