import React, { Component } from "react";
import { debounce } from "lodash";
import Studio from "@/helper/Studio";
import { openCamera, paintingVideoOnCanvas, setVideoSrc } from "@/helper/webcam";
import stl from "./Mirror.module.scss";

/**
 * 试妆镜
 * 展示摄像头内容和当前选择佩戴的眼镜
 */
export default class Mirror extends Component {
  constructor(props) {
    super(props);
    this.refVideo = React.createRef();
    this.refCanvas = React.createRef();
    this.state = { error: undefined };

    this.offlineCanvas = document.createElement("canvas");
  }
  componentDidMount() {
    // TODO remove
    const spec = window.innerHeight < 640 ? undefined : { width: { ideal: 720 } };
    openCamera({ video: { ...spec, facingMode: "user" } })
      .then((stream) => {
        const { current: videoEl } = this.refVideo;
        setVideoSrc(videoEl, stream);

        videoEl.oncanplay = this.handleVideoCanPlay; // 控制帧率
        videoEl.play();
      })
      .catch((err) => {
        this.setState({ error: "相机打开失败" + err.message });
      });
    this.photoStudio = new Studio(this.refCanvas.current);
  }
  componentWillUnmount() {
    if (this.refVideo.current) {
      this.refVideo.current.pause();
      this.refVideo.current.srcObject = null;
      this.refVideo.current.removeEventListener("canplay", this.handleVideoCanPlay);
    }
    this.photoStudio && this.photoStudio.dispose();
    this.paintingHandler && this.paintingHandler.cancel();
    this.refVideo.current && this.refVideo.current.pause();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.glasses !== this.props.glasses) {
      console.log("we received a new glasses", this.props.glasses);
      this.wareGlasses(this.props.glasses);
    }
  }

  // 展示摄像头
  handleVideoCanPlay = () => {
    // 渲染视频到离线canvas
    this.paintingHandler = paintingVideoOnCanvas(this.refVideo.current, this.offlineCanvas, 20);
    // 初始化摄影棚并启动渲染
    this.photoStudio.initDynamicBackground(this.offlineCanvas);
    this.photoStudio.render();
    // 调整当前场景尺寸
    const { clientWidth, clientHeight } = this.refCanvas.current;
    this.setState({ clientWidth, clientHeight });
  };

  // 更换眼镜
  wareGlassesUnDebounceRawFunc = (glasses) => {
    this.photoStudio.changeGlasses(glasses);
  };
  wareGlasses = debounce(this.wareGlassesUnDebounceRawFunc, 1500);

  render() {
    const { error, clientWidth, clientHeight } = this.state;
    return (
      <div className={stl.mirror} style={{ width: clientWidth, height: clientHeight }}>
        <video autoPlay style={{ display: "none" }} ref={this.refVideo}></video>
        <canvas className={stl.cover} ref={this.refCanvas}></canvas>

        {!!error && <div className={stl.error}>{error}</div>}
      </div>
    );
  }
}
