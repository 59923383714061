import React, { Component } from "react";
import { Link } from "react-router-dom";
import { loadModels } from "../api/face";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    loadModels().then(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    const { loaded } = this.state;
    if (loaded === true)
      return (
        <div className="home">
          <h2>Facial Recognition App</h2>
          <div className="nav">
            <li>
              <Link to="/photo">Face 68 Points</Link>
            </li>
            <li>
              <Link to="/camera">Try camera for you</Link>
            </li>
            <li>
              <Link to="/camera-3d">Try camera 3D for you</Link>
            </li>

            <li>
              <Link to="/glasses">Draw glasses 3D</Link>
            </li>
            <li>
              <Link to="/live-try">Live Try</Link>
            </li>
          </div>
        </div>
      );
    return <div className="home">Loading··· few mininutes</div>;
  }
}
