import * as faceapi from "face-api.js";

// tiny_face_detector options
let INPUT_SIZE = 256;
let SCORE_THRESHOLD = 0.6;

function getCurrentFaceDetectionNet() {
  return faceapi.nets.tinyFaceDetector;
}

function isFaceDetectionModelLoaded() {
  return !!getCurrentFaceDetectionNet().params;
}

// Load models and weights
export async function loadModels() {
  if (isFaceDetectionModelLoaded()) {
    return;
  }
  const MODEL_URL = process.env.PUBLIC_URL + "/models";
  await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
  await faceapi.loadFaceLandmarkTinyModel(MODEL_URL);
  //   await faceapi.loadFaceRecognitionModel(MODEL_URL);
}

const OPTION = new faceapi.TinyFaceDetectorOptions({
  inputSize: INPUT_SIZE,
  scoreThreshold: SCORE_THRESHOLD,
});

/**
 * 检测人脸信息
 * @param {canvas|image|video} input 待检测内容
 */
export async function detectFaceDesc(input) {
  const useTinyModel = true;

  let fullDesc = await faceapi.detectSingleFace(input, OPTION).withFaceLandmarks(useTinyModel);
  return fullDesc;
}
