import { loadTexture } from "@/helper/threeUtil";
import Glasses from "./Glasses";

export default class GlassesManager {
  /**
   * @type Map
   */
  cachedMap = null;

  constructor() {
    this.cachedMap = new Map();
  }

  dispose = () => {
    this.cachedMap.forEach((g) => g.dispose());
    this.cachedMap.clear();
    this.cachedMap = null;
  };

  /**
   * 添加眼镜，根据眼镜id进行缓存
   * @param {glasses} glasses 眼镜信息结构体
   * @returns {Glasses} 返回眼镜镜架对象
   */
  add = async (glasses) => {
    if (!glasses) return Promise.resolve(glasses);

    // 根据镜架信息造镜架，然后放进Set缓存，使用镜架ID作为索引
    const { id, model } = glasses;
    const cache = this.cachedMap.get(id);
    if (cache) return Promise.resolve(cache);

    const { frame, legL, legR } = model?.texture;
    return Promise.all([loadTexture(frame), loadTexture(legL), loadTexture(legR)]).then(([textureFrame, textureTempleL, textureTempleR]) => {
      const gls = new Glasses(textureFrame, textureTempleL, textureTempleR);
      this.cachedMap.set(id, gls);
      return gls;
    });
  };
  get = (id) => {
    return this.cachedMap.get(id);
  };
}
